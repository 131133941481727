<template>
  <div v-if="!isPageVisible">
    <b-card
      no-body
      style="height: 150px"
      class="d-flex justify-content-center align-items-center"
    >
      <h3>Resource Not Available</h3>
    </b-card>
  </div>
  <div v-else-if="user_type === 'agent'">
    <b-card
      no-body
      style="height: 150px"
      class="d-flex justify-content-center align-items-center"
    >
      <h3>"Previledge access only to Aliff Franchise."</h3>
    </b-card>
  </div>
  <div v-else>
    <!-- Filters -->
    <b-card no-body>
      <div class="m-1">
        <b-row cols="12" class="mb-1 px-2">
          <b-col cols="6">
            <!-- Search -->
            <b-input-group>
              <b-input-group-prepend is-text>
                <b-icon-search />
              </b-input-group-prepend>
              <b-form-input
                type="text"
                autofocus
                v-model="filters.searchText"
                placeholder="Search"
              />
            </b-input-group>
          </b-col>
          <b-col cols="2">
            <!-- Country Filter -->
            <v-select
              placeholder="Select Country"
              v-model="filters.country_id"
              :reduce="(country) => country.country_id"
              label="country_name"
              :options="this.$store.state.home.Countries"
            />
          </b-col>
          <!-- <b-col cols="2"> -->
          <!-- <b-row cols="12"> -->
          <b-col cols="2">
            <button
              class="btn btn-primary w-100"
              @click="getAllUniversityUSP()"
            >
              Search
            </button>
          </b-col>
          <b-col cols="2">
            <button class="btn btn-primary w-100" @click="resetFilters()">
              Reset
            </button>
          </b-col>
          <!-- </b-row> -->
          <!-- </b-col> -->
        </b-row>
        <b-row class="mb-1 px-3 py-1">
          <b-col cols="2">
            <multi-state-switch
              label="Gap Acceptable"
              :options="mss_options"
              v-model="filters.gap_acceptable"
              @input="getAllUniversityUSP()"
            />
          </b-col>

          <b-col cols="2">
            <multi-state-switch
              label="ATKT Acceptable"
              :options="mss_options"
              v-model="filters.atkt_acceptable"
              @input="getAllUniversityUSP()"
            />
          </b-col>

          <b-col cols="2">
            <multi-state-switch
              label="LOR"
              :options="mss_options"
              v-model="filters.lor"
              @input="getAllUniversityUSP()"
            />
          </b-col>

          <b-col cols="2">
            <multi-state-switch
              label="SOP"
              :options="mss_options"
              v-model="filters.sop"
              @input="getAllUniversityUSP()"
            />
          </b-col>

          <b-col cols="2" class="text-center">
            <multi-state-switch
              label="15 Year Eduaction Acceptable"
              :options="mss_options"
              v-model="filters.education_acceptance"
              @input="getAllUniversityUSP()"
            />
          </b-col>

          <b-col cols="2">
            <multi-state-switch
              label="IELTS Waiver"
              :options="mss_options"
              v-model="filters.ielts_waiver"
              @input="getAllUniversityUSP()"
            />
          </b-col>
        </b-row></div
    ></b-card>

    <!-- Universirty List -->
    <b-card no-body>
      <b-table
        responsive
        show-empty
        class="position-relative"
        empty-text="No matching records found"
        :per-page="pagination.perPage"
        :current-page="pagination.currentPage"
        :items="universityUSPList"
        :fields="universityUSPOptions"
        selectable
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            @change="row.toggleDetails"
          >
          </b-form-checkbox>
        </template>
        <template #row-details="row">
          <div class="bg-light rounded-lg p-5" v-if="row.item">
            <b-row cols="12" class="mb-1 border p-2 rounded-lg bg-white">
              <b-col>
                <h5>Universirty USP</h5>
                <p class="pr-5 mr-5">{{ row.item.usp_desc || "-" }}</p>
              </b-col>
            </b-row>

            <b-row cols="12" class="mb-1 g-3" style="gap: 10px">
              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">Gap Acceptable</h5>
                <h1 class="text-center">
                  {{
                    row.item.usp_gap_acceptable
                      ? row.item.usp_gap_acceptable.toUpperCase()
                      : "-"
                  }}
                </h1>
              </b-col>

              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">ATKT Acceptable</h5>
                <h1 class="text-center">
                  {{
                    row.item.usp_atkt_acceptable
                      ? row.item.usp_atkt_acceptable.toUpperCase()
                      : "-"
                  }}
                </h1>
              </b-col>

              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">LOR</h5>
                <h1 class="text-center">
                  {{ row.item.usp_lor ? row.item.usp_lor.toUpperCase() : "-" }}
                </h1>
              </b-col>
              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">SOP</h5>
                <h1 class="text-center">
                  {{ row.item.usp_sop ? row.item.usp_sop.toUpperCase() : "-" }}
                </h1>
              </b-col>
              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">15 Year Education Acceptance</h5>
                <h1 class="text-center">
                  {{
                    row.item.usp_education_acceptance
                      ? row.item.usp_education_acceptance.toUpperCase()
                      : "-"
                  }}
                </h1>
              </b-col>
              <b-col class="border p-2 rounded-lg bg-white">
                <h5 class="text-center h-50">IELTS Waiver</h5>
                <h1 class="text-center">
                  {{
                    row.item.usp_ielts_waver
                      ? row.item.usp_ielts_waver.toUpperCase()
                      : "-"
                  }}
                </h1>
              </b-col>
            </b-row>
            <b-row cols="12" class="mb-1 border p-2 rounded-lg bg-white">
              <b-col cols="10">
                <h5>Bachelor / Undergraduate Scholarship Details</h5>
                <p class="mb-2">{{ row.item.usp_bachelor }}</p>
                <a target="_blank" :href="row.item.usp_ug_sship_link"
                  >Visit Undergraduate Scholarship Link</a
                >
              </b-col>
            </b-row>
            <b-row cols="12" class="border p-2 rounded-lg bg-white">
              <b-col cols="10">
                <h5>Masters / Postgraduate Scholarship Details</h5>
                <p class="mb-2">{{ row.item.usp_masters_or_pg }}</p>
                <a target="_blank" :href="row.item.usp_pg_sship_link"
                  >Visit Postgraudate Scholarship Link</a
                >
              </b-col>
            </b-row>
          </div>
        </template>
        <template #cell(usp_id)="row"> {{ row.index + 1 }} </template>
      </b-table>
      <div class="mx-2" v-if="universityUSPList.length > 0">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="universityUSPList.length"
          base-url="#"
          align="right"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BModal,
  BTooltip,
  BPagination,
  BFormRadio,
  BFormGroup,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BIconSearch,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CommonServices from "@/apiServices/CommonServices";
import MultiStateSwitch from "@/views/components/MultiStateSwitch.vue";

export default {
  components: {
    vSelect,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BFormGroup,
    BFormRadio,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BIconSearch,
    MultiStateSwitch,
  },
  data() {
    return {
      filters: {
        searchText: "",
        country_id: null,
        gap_acceptable: "",
        atkt_acceptable: "",
        lor: "",
        sop: "",
        education_acceptance: "",
        ielts_waiver: "",
      },
      universityUSPList: [],
      universityUSPOptions: [
        { key: "show_details", label: "Show", sortable: false },
        { key: "usp_id", label: "USP ID", sortable: false },
        { key: "university_name", label: "University Name", sortable: false },
        { key: "country_name", label: "Country", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      countriesFilterOptions: [],
      mss_options: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Yes",
          value: "Y",
        },
        {
          label: "No",
          value: "N",
        },
      ],
      user_type: null,
    };
  },
  computed: {
    isPageVisible() {
      const user_details = this.$store.getters["user/getUserDetails"];
      this.user_type = user_details.user_type;
      if (user_details && Array.isArray(user_details.allocated_resources)) {
        const allowcatedResource = user_details.allocated_resources.find(
          (elem) => elem === "university_usp"
        );
        if (allowcatedResource) {
          this.$store.dispatch("home/onGetCountries");
          this.getAllUniversityUSP();
          return true;
        }
        return false;
      }
      return false;
    },
  },
  methods: {
    async getAllUniversityUSP() {
      try {
        const res = await CommonServices.getAllUniversityUSP({
          ...this.filters,
        });
        this.universityUSPList = res.data.data;
      } catch (error) {
        console.error(`Error in getStatusWiseStudents `, error);
      }
    },

    async resetFilters() {
      this.filters = {
        searchText: "",
        country_id: null,
      };
      this.getAllUniversityUSP();
    },
  },
  mounted() {},
};
</script>
